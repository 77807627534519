.organicfarms-container{
    width: 90%;
    margin: auto;
    margin-top: 20px;
}
.main-banner h1{
    color: #952d99;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
    font-family: 'Graphik-Bold';
    text-transform: uppercase;
}
.back-home{
    width: 90%;
    text-align: right;
    margin: auto;
    margin-top: 20px;
}
.organicfarms-container p{
    font-size: 16px;
    padding-bottom: 20px;
    line-height: 20px;
    text-align: justify;
}
.organicfarms-wrap h2{
    text-align: center;
    background-color: #952d99;
    margin: auto;
    color: #fff;
    width: 50%;
    padding: 5px;
    font-size: 22px;
}
@media(min-width:1300px){
    .organicfarms-wrap h2{
        width: 45%;
    }
}
.organicfarms-container h3{
    color: #7500c0;
    margin-top: 20px;
}
.about-list{
    margin-top: 20px;
    list-style-type: square;
    margin-left: 30px;
}
.about-list li{
    margin-bottom: 5px;
    line-height: 20px;
}
.brother-card-img{
    width: 100%;
    display: block;
}
@media(min-width:768px){
    .organicfarms-row{
        float: left;
    }
    .organicfarms-row.row1{
        width: 35%;
    }
    .organicfarms-row.row2{
        width: 65%;
    }
}
.organicfarms-box{
    margin: 20px;
    margin-bottom: 0px;
}
.organicfarms-row.row1 .organicfarms-box{
    margin-left: 0px;
}
.organicfarms-row.row2 .organicfarms-box{
    margin-right: 0px;
}
.organicfarms-wrap::after{
    display: block;
    clear: both;
    content: "";
}

@media(min-width:768px){
    .talk-row{
        width: 50%;
        float: left;
    }
}
.talk-row.row1{
    border-right: solid 1px #BBBBBB;
}
.talk-wrap::after{
    display: block;
    clear: both;
    content: "";
}
.talk-container{
    border-bottom: solid 1px #BBBBBB;
}
.talk-box{
    margin: 20px;
}
.talk-box.box1{
    margin-left: 0px;
}
.talk-box.box2{
    margin-right: 0px;
}
.talk-lists{
    margin-left: 30px;
    list-style: none;
}
ul.talk-lists li:before {
    content: '✓';
    padding-right: 10px;
}

@media(min-width:768px){
    .about-brother-row{
        float: left;
        width: 50%;
    }
    .about-brother-row.row1{
        width: 70%;
    }
    .about-brother-row.row2{
        width: 30%;
    }
}
.about-brother-wrap::after{
    display: block;
    clear: both;
    content: "";
}
.about-brother-box{
    margin: 20px;
}
.about-brother-box.box1{
    margin-left: 0px;
}
.about-brother-box.box2{
    margin-right: 0px;
}