.searchnav-container{
    background-color: #fff ;
    width: 100%;
    padding: 10px;
}
.searchnav-wrap{
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: auto;
}
.logo-container{
    display: flex;
    align-items: flex-end;
    width: 50%;
}
.logo-container p{
    font-size: 19px;
    padding: 1px 1px 1px 25px;
    color: #6e0089;
    font-weight: bold;
    /* margin-left: 25px; */
    /* border-left:  2px solid #D8D8D8;  */
    /* font-family: 'graphik-bold'; */
}
.verticalbar{
    background-color: #D8D8D8;
    min-width: 1px;
    width: 1px;
    height: 30px;
    margin-left: 30px;
}
.searchnav-row{
display: flex;
justify-content: space-between;
}
.searchnav-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;
}
.user-container{
    display: flex;
    justify-content: flex-end;
    /* padding: 5px; */
}
.searchnav-row .user-img{
    border-radius: 90%;
}
.acc-logo{
    width: 16%;
    padding: 5px;
}
@media(max-width:767px){
    .acc-logo{
        width: 50%;
    }
    .user-img{
        width: 18%;
    }
}
@media(min-width:768px) and (max-width:1199px){
    .acc-logo{
        width: 25%;
    }
    .user-img{
        width: 10%;
    }
}
@media(min-width:1200px)and (max-width:1500px){
    .user-img{
        width: 15%;
    }
}
@media(min-width:1501px)and (max-width:1650px){
    .user-img{
        width: 15%;
    }
    .logo-container h4{
        font-size: 21px;
    }
}
@media(min-width:1651px){
    .user-img{
        width: 15%;
    }
    .logo-container h4{
        font-size: 24px;
    }
}

@media (min-width:1200px)and (max-width:1280px) {
    .logo-container p{
        font-size: 16px;
    }
}