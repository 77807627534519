

.sn-main-header{
    color: #7500c0;
    margin-top: 0px !important;
}

.sn-main-span{
    color:black !important;
}

.sn-about-header {
    color: #7500c0 !important;
    margin-top: 12px !important;
    text-align: center !important;
}

.sn-about-para{
    font-size: 11px !important;
    padding-bottom: 0 !important;
}

.session_containers{
    display:flex;
    justify-content: space-between;
    margin-bottom: 2%;
    
}
.session_wrap{
    border: 1px solid #7500c0;
    padding: 12px;
    width:40%;
    margin:auto;
}

.session_header {
    color: #7500c0 !important;
    margin-top: 12px !important;
    text-align: center !important;
    margin-bottom: 6px !important;
}
.session_para{
    color:#7500c0 !important;
    padding-bottom: 0px !important;
    font-weight: bold !important;
}

.session_span{
    color:black;
    font-weight: bold;
}

.talk-wrap-sn{
    margin-bottom: 3%;
}
.sn-card-img{
    width: 100%;
    display: block;
    border: 1px solid #8a2be24f;
}

.sn-about-anchor_section{
    margin: auto;
    padding: 8px;
    background-color: #7500c0;
    color: white !important;
    text-align: center;
    width: 35%;
}

.sn-about-anchor{
    color: #fff;
    font-weight: 700;
    text-decoration: none;
}