.footer_container p{
    background: #460073;
    color: #fff;
    text-align: center;
    padding: 8px;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 20;
    font-size: 12px;
}
.footer_container{
    margin-top: 30px;
}