.entrepreneurship-container{
    /* background-color: #eee;
    box-shadow: inset 0 0 10px #999; */
}
.entrepreneurship-wrap{
    width: 85%;
    margin: auto;
    padding-top: 30px;
    /* padding-top: 35px; */
    /* padding-bottom: 45px; */
}
.entrepreneurship-wrap h2{
    font-size: 24px;
    font-family: 'graphik-bold';
    margin-bottom: 20px;
    font-weight: bold;
    /* border-right: 4px solid #6c00ba;
    border-left: 4px solid #6c00ba;
    width: 45%; */
    text-align: center;
    margin: 1% auto;
}

.entrepreneurship-wrap h2 span{
    position: relative;
}
.entrepreneurship-wrap h2 span:before{
    position: absolute;
    width: 4px;
    height: 28px;
    content: " ";
    background: #6c00ba;
    top: 5px;
    left: -15px;
}
.entrepreneurship-wrap h2 span:after{
    position: absolute;
    width: 4px;
    height: 28px;
    content: " ";
    background: #6c00ba;
    top: 5px;
    right: -15px;
}


.entrepreneurship-wrap p{
    font-size: 14px;
    margin-bottom: 20px;
    padding-top: 15px;
}
.entrepreneurship-wrap p a{
    color: #520079;
}
.text-highlighter{
    font-weight: 700;
}
.entrepreneur-anchortag{
    text-decoration: none;
}
.entrepreneur-box{
    position: relative;
    /* margin-bottom: 10px; */
    border: 1px solid #ddddddb8;
    box-shadow: 0px 0px 3px #ddd;
    height: 205px;
}
.entrepreneur-anchortag .imageText{
    position: absolute;
    width: 100%;
    bottom: 0;
    /* background: rgba(255,255,255,.6); */
    background-color: white;
    color: #520079;
}
@media(min-width:768px){
    /* .entrepreneur-row{
        float: left;
        width: 50%;
        /* margin-left: 25%; */
    /* } */ 
}
.entrepreneur-wrap::after{
    display: block;
    clear: both;
    content: "";
}
.entrepreneur-box{
    /* margin: 10px; */
}
.entrepreneur-box.box1{
    /* margin-left: 0px; */
}
.entrepreneur-box.box2{
    /* margin-right: 0px; */
}

.entrepreneur-box.box3{
    /* margin-left: 0px;
    margin-top: 20px; */
}
.entrepreneur-box .card-imgs{
    width: 100%;
    display: block;
}
.entrepreneur-box h3{
    text-align: center;
    /* background-color: #520079; */
    padding: 8px 0px;
    color: #520079;
    font-size: 11px;
}
/* .margin-T{
    margin-top: 2rem;
} */
.topic_header{
    margin-top:-2% !important;
}
@media(max-width:1300px){
    .entrepreneur-box h3{
        font-size: 10px;
    }
    .entrepreneur-row h5 a{
        color: #520079;
        font-size: 11px !important;
    }
    .entrepreneur-box{
        height:182px;
    }
}
.entrepreneur-row h5{
    display: flex;
    justify-content: center;
}
.entrepreneur-row h5 a{
    color: #520079;
    font-size: 12px;
}

.recording_icon{
    margin-left: 20%;
    width: 35%;
    height: auto;
    cursor: pointer;
}

/* previous year edition tab  */
.entrepreneurship-box-row {
    margin-bottom: 10px;
    display: flex;
    margin-top: 25px;
}

.entrepreneurship-box-row-para.active {
    color: #8a00c0eb;
    /* font-weight: bold; */
    /* padding-right: 1%; */
}

.entrepreneurship-box-row-para{
    font-family: "Graphik-Medium";
}
.entrepreneurship-box-row-para.inactive {
    color: #c6c6c6;
}

.entrepreneurship-box-row .vertical-line {
    border-left: 2px solid #c6c6c6;
    padding-right: 20px;
    margin-left: 20px;
}
.entrepreneurship-box-row p{
    font-size: 16px !important;
    margin-bottom: 0px !important;
}
.entrepreneur-wrap{
    padding-top: 1%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2%;
}

.entrepreneur-wrap1{
    padding-top: 1%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2%;
    margin-top:1%;
}
.entrepreneurship-container{
    position: relative;
    margin-bottom: -1%;
}
.entrepreneurship-container .bg5{
    position: absolute;
    top: 72%;
    left: 0%;
    width: 15%;
}

 .entrepreneurship-container .bg6{
    position: absolute;
    bottom: 72%;
    left: 92%;
    width: 8%;
 }
 @media(min-width:992px) and (max-width:1299px){
    .entrepreneurship-wrap h2{
        /* width: 54%; */
    }
}

@media(min-width:1409px) and (max-width:1450px){
    .entrepreneur-box {
        height: 195px;
    }
}