@font-face {
  font-family: 'Graphik-Bold';
  src: url('./fonts/Graphik/Graphik-Bold.woff2') format('woff2'),
      url('./fonts/Graphik/Graphik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik-Regular';
  src: url('./fonts/Graphik/Graphik-Regular.woff2') format('woff2'),
      url('./fonts/Graphik/Graphik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik-Medium';
  src: url('./fonts/Graphik/Graphik-Medium.woff2') format('woff2'),
    url('./fonts/Graphik/Graphik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body{
  font-family: 'Graphik-Regular';
}