.coreteam-container {
    /* background-color: #eee;
    box-shadow: inset 0 0 10px #999; */
}

.coreteam-wrapper {
    width: 85%;
    margin: 0 auto;
}

.coreteam-wrapper {
    /* margin-top: 30px; */
    /* padding-top: 35px; */
    padding-bottom: 40px;
}

.coreteam-wrapper h2 {
    font-family: 'Graphik-Bold';
    font-size: 24px;
    font-weight: bold;
    /* border-right: 4px solid #6c00ba;
    border-left: 4px solid #6c00ba;
    width: 13%; */
    margin: 1% auto 2% auto;
    text-align: center;
}

.coreteam-wrapper h2 span{
    position: relative;
}
.coreteam-wrapper h2 span:before{
    position: absolute;
    width: 4px;
    height: 28px;
    content: " ";
    background: #6c00ba;
    top: 5px;
    left: -15px;
}
.coreteam-wrapper h2 span:after{
    position: absolute;
    width: 4px;
    height: 28px;
    content: " ";
    background: #6c00ba;
    top: 5px;
    right: -15px;
}


.team-leads-container {
    /* justify-content: space-between; */
    width: 100%;
    margin-top: 45px;
    display: grid;
    /* justify-content: space-between; */
    /* width: 100%; */
    /* flex-wrap: wrap; */
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2%;
    row-gap: 15%;
    /* box-shadow: 1px 1px 3px solid grey; */
}

.team-card {
    /* width: 18%; */
    background-image: url("../images/Coreteam/coreteam-bg.jpg");
    /* background-size: cover; */
    background-size: 100% 100%;
    min-height: 175px;
    border-radius: 12px;
    text-align: center;
    /* margin-right: 20px; */
    position: relative;
}

.lead-img {
    width: 70px;
    margin-top: -20px;
}

.lead-details {
    padding: 0 5px;
}

.lead-details h4 {
    /* font-family: 'Graphik-Bold'; */
    margin: 5px 0px;
    font-size: 15px;
}

.lead-details p {
    font-size: 12px;
    font-weight: 500;
    /* min-height: 40px; */
}

.contact-icons {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    width: 100%;
    position: absolute;
    bottom: 20px;
}

.contact-icons .icons {
    /* width: 14px; */
    height: 18px;
    margin-right: 12px;

}

@media screen and (min-width:1240px) and (max-width:1544px) {
    .coreteam-wrapper {
        width: 85%;
    }
}

@media screen and (min-width:1545px) {
    .coreteam-wrapper {
        width: 85%;
    }
}

@media screen and (min-width:1024px) and (max-width:1199px) {
    .team-card {
        min-height: 175px;
        /* width: 20%; */
    }
}

@media screen and (min-width:1200px) and (max-width:1280px) {
    .team-card {
        min-height: 175px;
        /* width: 18%; */
    }
}


@media screen and (min-width:1361px) and (max-width:1468px) {
    .team-card {
        min-height: 175px;
        /* width: 18%; */
    }
}

@media screen and (min-width:1469px) and (max-width:1544px) {
    .team-card {
        min-height: 175px;
        /* width: 17%; */
    }
}

@media screen and (min-width:600px) and (max-width:992px) {
    .team-leads-container {
        justify-content: center;
        flex-wrap: wrap;
    }

    .team-card {
        width: 33%;
        margin-bottom: 30px;
    }

    .coreteam-wrapper {
        padding-bottom: 10px;
    }
}

@media screen and (min-width:440px) and (max-width:599px) {
    .team-leads-container {
        justify-content: center;
        flex-wrap: wrap;
    }

    .team-card {
        width: 50%;
        margin-bottom: 30px;
    }

    .coreteam-wrapper {
        padding-bottom: 10px;
    }
}

@media screen and (max-width:439px) {
    .team-leads-container {
        justify-content: center;
        flex-wrap: wrap;
    }

    .team-card {
        width: 100%;
        margin-bottom: 30px;
    }

    .coreteam-wrapper {
        padding-bottom: 10px;
    }
}

.coreteam-container {
    position: relative;
}

.coreteam-container .bg13 {
    position: absolute;
    top: 71%;
    left: 0%;
    width: 7%;

}

.coreteam-container .bg14 {
    position: absolute;
    bottom: 67%;
    left: 92%;
    width: 8%;
}
@media(min-width:992px) and (max-width:1299px){
    .coreteam-wrapper h2{
        width: 15%;
    }
}