.lastsection_june {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    border: 1px solid #80807e4f;
    margin-top: 20px;
    width: 100%;
}


.midsection_li_june li::before {
    display: inline-block;
    vertical-align: middle;
    width: 5px;
    height: 5px;
    background-color: black;
    margin-right: 20px;
    content: ' ';
    margin-left: -26px;
}


.midsection_li_june ul {
    list-style-type: none;
    margin-left: 2%;
}

.midsection_li_june{
    padding-top: 20px;
  }

  .midsection_li_june ul li{
    margin-bottom:    6px;
    margin-left: 20px;
    }
  