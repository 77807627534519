.views-page {
    width: 50%;
    margin: 40px auto;
}

#user_view {
    background-color: white;
    position: absolute;
    top: 20%;
    left: 36%;    
    border-collapse: collapse;
    width: 30%;
    border: 4px solid white;
    box-shadow: 40
}

#user_view th,td {
    padding: 5px 10px;
    border: 1px solid #ddd;
    text-align: center;
}

#user_view tr:nth-child(even) { 
    background-color: #f2f2f2;
}

#user_view tr:hover {
    background-color: #ddd;
}

#user_view th {
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #04AA6D;
    color: white;
}

.views-page .canvasjs-chart-credit {
    display: none;
}

.bar-charts__container {
    width: 50%;
    margin: 35px auto 0px auto;
    padding-bottom: 30px;
}

.sustainbility_news__barchart {
    margin-top: 0px;
}

.barchart_heading__container {    
    font-size: 1.6vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6e0089;
    font-weight: bolder;    
}

.barchart_heading__container .react-datepicker-wrapper {
    display: inline-block;
    width: 7%;
    margin-right: 8px;
}

.barchart_heading__container .date-picker-input-btn {
    cursor: pointer;
    padding: 5px 10px;
    border: 0;
    border-radius: 4px;
    background-color: #7500c0;
    font-size: 1vw;
    color: #fff;
}

.barchart_heading__container .react-datepicker__year-wrapper {    
    max-width: 204px !important;
}

.barchart_heading_text {
    text-decoration-line: underline;
    text-decoration-thickness: 5px; 
}

.sustainbility_articles__barchart {
    margin-top: 30px;
    font-size: 22px;
    color: #6e0089;
    font-weight: bolder;
    text-align: center;
}