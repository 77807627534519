.sustainability_mid_section_july {
    width: 90%;
    margin: auto;
    padding-top: 20px;
    /* padding-bottom: 20px; */
}

.sustainability_quiz_section{
    width:90%;
    margin:auto;
    padding-top: 1%;
    padding-bottom: 1%;
}

.sustainability_hr_div_quiz{
    width:100%;
    margin: auto;
}

.quiz_section{
    padding: 12px;
    width: 20%;
    margin: auto;
    text-align: center;
    background: #7500c0b8;
    border-radius: 10px;
    color: white;
    margin-top: 2%;
    border: 1px solid #460073;
    box-shadow: 0px 0px 8px 3px #80808066;
    font-weight: bold;

}

.quiz_link{
    text-decoration: none;
    color: white;
}

.priority-learnings-container_july {
    width: 90%;
    margin: auto;
    padding-top: 1%;
}

.quiz_para{
    padding-top: 6px;
}