.img-responsive {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.whitePaperContainer {
  /* background-color: #eee;
    box-shadow: inset 0 0 10px #999; */
}

.paperArticalWrap h2 {
  font-family: 'Graphik-Bold';
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
  /* border-right: 4px solid #6c00ba;
  border-left: 4px solid #6c00ba;
  width: 27%; */
  margin: 1% auto 2% auto;
  text-align: center;
}

.paperArticalWrap h2 span{
  position: relative;
}
.paperArticalWrap h2 span:before{
  position: absolute;
  width: 4px;
  height: 28px;
  content: " ";
  background: #6c00ba;
  top: 5px;
  left: -15px;
}
.paperArticalWrap h2 span:after{
  position: absolute;
  width: 4px;
  height: 28px;
  content: " ";
  background: #6c00ba;
  top: 5px;
  right: -15px;
}


.paperArticalWrap {
  width: 85%;
  margin: auto;
  padding-top: 15px;
  padding-bottom: 35px;
}

.paperArticalWrap p{
  font-size: 14px;
  margin-bottom: 20px;
}
.WP-cardWrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3%;
}

.WP_card_box {
  position: absolute;
  top: 64%;
  left: 4.5%;
  color: white;
  right: 10%;
}

.WP_card_box_header {
  font-family: 'Graphik-Bold';
}

.WP_card_box_para {
  padding-top: 10px;
}

.WP_card_box_para1 {
  padding-top: 0px !important;

}
.WP_card_box_para1 a{
  text-decoration: none;
}
.WP_card_box_para1 a:hover{
  text-decoration: underline;
}
.WP_card_box_anchor {
  color: aqua;
}
.WP_Card_box_p_wrap{
  position: absolute;
  bottom: 2%;
  left: 4.5%;
  right: 10%;
}
.Papaer-Artical-card {
  /* border: 1px solid #bebebe;
    border-radius: 15px; */
  position: relative;
}

.cardinfo-Text {
  padding: 20px;
  background: #fff;
  border-radius: 0px 0px 15px 15px;
}

.cardinfo-Text h3 {
  margin-bottom: 15px;
}

.vieMoreLink a {
  color: #3164f2;
  font-weight: 600;
  /* text-decoration: none; */
}


@media screen and (min-width:540px) and (max-width:767px) {
  .WP-cardWrap {
    grid-template-columns: 1fr 1fr;
    gap: 2%;
  }

  .cardinfo-Text {
    padding: 15px;
  }

  .cardinfo-Text h3 {
    font-size: 16px;
  }

  .cardinfo-Text p {
    font-size: 14px;
  }

  .Papaer-Artical-card {
    margin-bottom: 20px;
  }
}

@media screen and (max-width:539px) {
  .WP-cardWrap {
    display: block;
  }

  .cardinfo-Text {
    padding: 15px;
  }

  .cardinfo-Text h3 {
    font-size: 16px;
  }

  .cardinfo-Text p {
    font-size: 14px;
  }

  .Papaer-Artical-card {
    margin-bottom: 20px;
  }
}

.whitePaperContainer {
  position: relative;
  margin-bottom: -3%;
}

.whitePaperContainer .bg9 {
  position: absolute;
  top: 79%;
  left: 1%;
  width: 7%;

}

.whitePaperContainer .bg10 {
  position: absolute;
  bottom: 76%;
  left: 93%;
  width: 7%;
}
@media(min-width:992px) and (max-width:1299px){
  .paperArticalWrap h2{
    width: 33%;
  }
}
@media(min-width:1200px) and (max-width:1360px){
.WP_card_box {
  position: absolute;
  top: 56%;
  left: 4.5%;
  color: white;
  right: 10%;
}
}