.feedback_label {    
    font-size: 24px;
    font-family: 'Graphik-Bold';
}

.question {
    font-size: 14px;  
    margin: 10px 0 0px 0;    
}    

.radio_button {
    margin: 7px 0px 0px 0px;
}

.submit button{    
    background: #eaeaea;
    color: #000;
    align-items: center;
    text-align: center;
    width: 91px;
    height: 38px;
    float: right;
    right: 22px;
    bottom: 17px;
    font-size: 14px;
    cursor: pointer;
    border: 0px;
}

.submit {
    text-align: center;
    padding-top: 10px;
}

.feedback-label {
    margin-left: 14px;  
    font-size: 14px;
}

.feedback-options {
    padding-top: 5px;
    font-size: 14px;  
}

.feedback-success {
    font-family: graphik-bold;    
    margin: 40px 0 9px 95px;
}

.success-submit {
    margin: 25px 0px 0px 221px;    
}

.feedback-button button{
    background: #7500c0;
    color: #fff;
    text-align: center;
    position: fixed;    
    width: 8%;    
    right: 6px;
    bottom: 70px;
    border-radius: 15px;
}
.rating_icon{
    padding-right: 3px;
    vertical-align: middle;
    width: 20px;
}
/* #feedback-btn-newui {
    background-color: #fafafa;
}
#feedback-btn-newui {
    position: fixed;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 6px rgb(0 0 0 / 50%);
    box-shadow: 0 2px 6px rgb(0 0 0 / 50%);
    width: 105px;
    height: 36px;
    overflow: hidden;
    bottom: 10%;
    right: -65px;
    transition: all .3s ease;
}
#feedback-btn-newui:hover{
    right: 0px;
}

#feedback-btn-newui .feedback-lbl {
    color: #575757;
    background-image: url(/public/images/feedback/feedback-icon-newui.svg);
}
#feedback-btn-newui .feedback-lbl {
    font-size: 12px;
    letter-spacing: .24px;
    cursor: pointer;
    position: absolute;
    display: block;
    width: 100px;
    height: 100%;
    line-height: 36px;
    padding-left: 40px;
    z-index: 10001;
    background-repeat: no-repeat;
    background-position: 10px;
    font-family: "Graphik bold",Arial,sans-serif;
} */

.feedback_btn_sec{
width: 90%;
margin: auto;
text-align: center;
/* padding-left: 20px; */
}
.feedback-lbl{
    cursor: pointer;
    color:blue;
    font-size: 12px;
}

.feedback-btn-close {
    cursor: pointer;
    display: block;
    width: 9px;
    height: 100%;
    line-height: 34px;
    text-align: center;
    position: absolute;
    right: 15px;
    background-position: center;
    background-image: url(/public/images/feedback/close.svg);
    background-repeat: no-repeat;
    z-index: 99999;
}

#feedback-input {
    border-bottom-color: #c6c6c6 !important;
    overflow: hidden;
    position: relative;
    max-height: 70px;
    border: 0;
    border-bottom: 0.5px solid;
    min-height: 21px;
    width: 356px;    
    display: block;
    margin-top: 5px;
    margin-bottom: 15px;
    resize: none;
    font-family: "Graphik",Arial,sans-serif !important;    
    outline: none;
    background: rgba(0,0,0,0);
    font-size: 14px;
    padding: 0 0 0 0;    
}

.star.active_star {
    background-image: url(/public/images/feedback/star_active.svg);
}

.inactive_star {
    background-image: url(/public/images/feedback/star_inactive.svg);
}

.star {
    background-repeat: no-repeat;
    background-size: 18px;
    width: 29px;
    margin-bottom: 20px;
    margin-top: 20px;
    cursor: pointer;
}

.feedback-rating {
    display: inline-flex;
    margin: 20px 0 20px 70px;
    gap: 0 15px;
}

.feedback-menu-arrow {
    border-top: 12px solid rgba(0,0,0,0);
    border-bottom: 12px solid rgba(0,0,0,0);
    border-left: 12px solid;
    position: fixed;
    right: 213px;
    bottom: 90px;
    display: block;
    border-left-color: #fff;
}

.feedback-rating img {
    cursor: pointer;
}

.feedback-success-msg {
    color: grey;
    margin: 0 0 40px 37px;
}

#thankYou-close {
    cursor: pointer;
    display: block;
    width: 17px;
    height: 17px;
    right: 17px;
    top: 17px;
    line-height: 34px;
    text-align: center;
    position: absolute;
    background-position: center;
    background-image: url(/public/images/feedback/close_btn.svg);
    background-repeat: no-repeat;
    z-index: 99999;
}

.star-text {
    margin: 20px 21px;
    font-weight: 600;
}
#star-text{
    padding: 0px;
    margin: 0px;
}


@media screen and (min-width:1025px) and (max-width:1140px){
    .feedback_btn_sec{
        width: 100%;
 }
}