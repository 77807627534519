.fddata_container{
    width:90%;
    margin:auto;
}

.fddata_container h2{
    text-align: center;
    color: #7500c0;
    font-family: 'Graphik-Bold';
}
.fddata_mid_sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.fddata_table {
    border: 2px solid rgb(224, 224, 224);
    width: 100%;
    border-spacing: unset;
    margin-top: 20px;
    
  }
  
  .fddata_table td{
    text-align: center;
    padding: 10px;
    border: 1px solid rgb(224, 224, 224);
    color:#7300BD;
  }

  .fddata_table th {
    border-bottom: 1px solid rgb(224, 224, 224);
    background-color:#7300BD ;
    padding: 10px;
    border-right: 1px solid rgb(192 192 192);
    border-left: 1px solid rgb(192 192 192);
    color: white;
    text-align: center;
  }

  .fddata_table tr:nth-child(even) {
    background: #ffffff;
}
.fddata_table tr:nth-child(odd) {
    background:  #e9e9e9;
   
}

.feedData_main_container .date-picker-input-btn {
    cursor: pointer;
    padding: 0px 10px;
    border: 0;
    border-radius: 4px;
    background-color: #7500c0;
    font-size: 15px;
    color: #fff;
}

.feedData_main_container .date-picker-container {
    display: flex;    
    align-items: center;
}

.feedData_main_container .filter-text {
    font-weight: 600;
}

.sr-feedback-download {
    font-weight: 600;
}

.sr-feedback-download a {
    text-decoration: none;
    color:#7500c0;
}

.sr-feedback-download a:hover {
    text-decoration: underline;
}