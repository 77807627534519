.aboutsession{
    display :grid;
    grid-template-columns: 70% 30%;
}

.aboutsection_bold{
    color: #7500c0;
}

.aboutsection_joinus_header {
    margin-top: 0px !important;
}

.aboutsession_textContent{
    margin-right: 2%
}
.session_details_section{
    margin-bottom: 2%;
}
.session_details_table{
    width:100%;
    border:1px solid black;
}

.session_details_table, th, td {
    border:1px solid black;
    text-align: left;
    border-collapse: collapse;
    padding-left: 2%;
    vertical-align: top;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .session_details_table td {
    font-size: 14px;
  }
  .session_details_table th {
    font-size: 16px;
  }

  .session_details_table_ul{
    margin-left: 6%;
  }

  .session_details_a{
    font-weight: bold;
    color: #4545acc4;
  }

  .session_details_a:hover{
    color:#952d99;
  }

  .aboutspeaker_header {
    margin-top: 0px !important; 
    margin-bottom: 1% !important;
}

.aboutsession_span{
    font-style: italic;
}
.session_details_table ul{ 
  list-style-type: none;
}

.session_details_table li {
  position: relative; 
 }

 .session_details_table ul li:before {
    display:          inline-block;
    vertical-align:   middle;
    width:            5px;
    height:           5px;
    background-color: black;
    margin-right:     10px;
    content:          ' ';
    margin-left: -4%;
 }