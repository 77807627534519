.float-icons-feedback {
    width: 28px;    
}
.float-icons{
    width: 28px;
    vertical-align: middle;
}
.float-box p{
    display: inline;
    font-size: 14px;
    padding: 0px;
    margin-right: 4px;
}
.float-box a{
    text-decoration: none;
    padding: 15px;
    padding-left: 0px;
    color: white;
    font-weight: 600;
}
.float-box a:hover {
     color: white !important; 
}
.float-box a img{
    margin-right: 5px;
}
.float-box{
    background-color:#520079;
    /* background-image: linear-gradient(to right, #7500c0, #460073); */
    padding: 9px;
    position: fixed;
    right: -108px;
    border-radius: 2px;
    -moz-transition: all .5s;
  -webkit-transition: all .5s;
  transition: all .5s;
  /* overflow-x: hidden; */
  /* z-index: 999; */
}
.float-box.box1{
    top: 60%;
    right: -108px;
    z-index: 10;
    text-align: center;
}
/* .float-box.box1{
    top: 40%;
    right: -112px;
    z-index: 10;
} */
.float-box.box2{
    top: 48.4%;
    right: -112px;
    z-index: 10;
}

.float-box.box3{
    top: 57%;
    right: -102px;
    z-index: 10;
}

.float-box:hover{
    right: -7px;
    -moz-transition: all .5s;
  -webkit-transition: all .5s;
  transition: all .5s;
}