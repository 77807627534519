.sustainability_mid_container{
    width:90%;
    margin:auto;
    padding-top: 30px;
}

.sustainability_para{
    padding-top: 20px;
}
.sustainability_para_a{
    font-weight: bold;
}
.sustainability_li_container{
    width:70%;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 30px;
}

.sustainability_li_container h3{
    text-align: center;
    background-color: #952d99;
    padding: 6px;
    color: white;
    width:70%;
    margin: auto;
}

.sustainability_wrap{
    width: 60%;
    margin: auto;
    padding-top: 20px;
}

.sustainability_wrap ul{ 
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    list-style-type: none;
    color: #871fb5;
}
.sustainability_wrap li::before {
    display:          inline-block;
    vertical-align:   middle;
    width:            5px;
    height:           5px;
    background-color: #871fb5;
    margin-right:     20px;
    content:          ' '
  }

  .sustainability_hr_div{
    width: 95%;
    margin: auto;
  }

  .sustainability_highlight_div{
    width: 90%;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 25px;
  }
.sustainability_highlight_div h4{
    color:#bf29c4;
    font-family: 'Graphik-Bold';
}
  .sustainability_main{
    padding-bottom: 40px;
  }

  .highlight_h4_tag{
    color: #871fb5 !important;
    padding-top: 20px;
  }

  .hightlight_a{
    color:black;
    font-weight: bold;
    display: inline-block;
    padding-top: 6px;
  }
  .hightlight_a:hover{
    color:#952d99;
  }

  .sustainability_mid_section{
    width: 90%;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .midsection_h4{
    color:#bf29c4;
    font-family: 'Graphik-Bold';
}

  .midsection_li{
    padding-top: 20px;
  }

  .midsection_li ul{
  list-style-type: none;
  }

  .midsection_li ul li{
    margin-bottom:    6px;
    margin-left: 45px;
    }
  

  .midsection_li li::before {
    display:          inline-block;
    vertical-align:   middle;
    width:            5px;
    height:           5px;
    background-color: black;
    margin-right:     20px;
    content:          ' ';
    margin-left: -25px;
  }

  .mid_section_a{
    font-weight: bold;
    color: #0303cec4;
  }

  .mid_section_a:hover{
    color:#952d99;
  }

  .mid_section_p{
    padding-top: 20px;
  }

  .mid_section_span{
    color:#bf29c4;
  }

  .midsec_box{
    display: flex;
  }

  .sust_news_img{
    width:100%;
    height: auto;
  }

  .midsection_para{
    padding-top: 20px;
    margin-right: 50px;
  }

.lastsection{
  display: flex;
  border: 1px solid #80807e4f;
  margin-top: 20px;
  width: 100%;
}

.lastsection_1stCard{
  background: #80807e1a;
    padding: 20px;
    width: 33.33%;
}
.lastsection_2ndCard{
    padding: 20px;
    width: 33.33%;
}

.lastsection_3rdCard{
  background: #80807e1a;
  padding: 20px;
  width: 33.33%;
}

.lastsection_h4{
  color: #871fb5;
  font-family: 'Graphik-Bold';

}

.sust_news_gif_img{
  width: 100%;
}

.go_back_button{
  width: 90%;
  margin: auto;
  text-align: right;
  padding-top: 20px;
}

.sustainability_header{
  width: 90%;
  margin: auto;
  padding-top: 20px;
}

.sustainability_header h2{
color: #952d99;
text-align: center;
padding-bottom: 20px;
font-family: 'Graphik-Bold';
}

@media screen and (min-width:768px) and (max-width:800px) {
  .sustainability_li_container {
    width: 90%;
}

.sustainability_li_container h3 {
  width: 90%;
}
}

@media screen and (min-width:801px) and (max-width:991px) {
  .sustainability_li_container {
    width: 90%;
}

.sustainability_li_container h3 {
  width: 80%;
}
}

@media screen and (min-width:992px) and (max-width:1199px) {
  .sustainability_li_container {
    width: 80%;
}

.sustainability_li_container h3 {
  width: 80%;
}
}

@media screen and (min-width:1200px) and (max-width:1400px) {
  .sustainability_li_container {
    width: 80%;
}

.sustainability_li_container h3 {
  width: 80%;
}
}