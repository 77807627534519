.diduknow_p {
    padding-top: 8px;
    word-spacing: 3px;
}

* {
    line-height: 1.2;
}

.lastsection_may {
    display: grid;
    grid-template-columns: 27.5% 45% 27.5%;
    border: 1px solid #80807e4f;
    margin-top: 20px;
    width: 100%;
}

.lastsection_may_1stCard {
    background: #80807e1a;
    padding: 20px;
    /* width: 33.33%; */
}

.lastsection_may_2ndCard {
    padding: 20px;
    /* width: 33.33%; */
}

.lastsection_may_3rdCard {
    background: #80807e1a;
    padding: 20px;
    /* width: 33.33%; */
}

.lastsection_may_h4 {
    padding-top: 2%;
    color: #871fb5;
    font-family: 'Graphik-Bold';

}
.sustainability_mid_container h2{
    text-align: center;
    font-family: "Graphik-Bold" !important;
    padding-bottom: 1%;
    color: #952d99;
}
.hightlight_b {
    font-weight: bold;
    /* color: #0303cec4; */
    color: blue;
}
.making-news{
    display: flex;
    gap: 1.5%;
    align-items: center;
    margin: 1.5% auto;
}
.making-news img{
    width: 35px;
    height: 35px;
}
.trends-sec{
    display: flex;
    gap: 2%;
    padding-bottom: 2%;
}
.midsection_li{
    display: flex;
    gap: 2%;
}
.midsection_li p{
    line-height: 1.3;
}
.priority-learnings-box{
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin-top: 2%;
    font-family: "Graphik-Medium";
    padding-bottom: 1%;
}
.priority-learnings-row a{
    color: blue;
    /* color: #0303cec4; */
    font-family: "Graphik-Medium";
}
.priority-learnings-container{
    width: 90%;
    margin: auto;
}
.priority-learnings-row p{
    padding-bottom: 2%;
}
.trends-sec img{
    width: 40px;
    height: 40px;
}
.highlight_h4_tag_may{
    color: #871fb5 !important;
    padding-bottom: 5px;
}
.vertical-line-may{
    border-right: 1px solid;
}
.dyk-1{
    width: 100%;
}
.dyk-2{
    width: 100%;
}
.dyk-3{
    width: 100%;
}
.mid_section_a{
    color: blue;
}
