.diduknow_p {
    padding-top: 8px;
    word-spacing: 3px;
}

* {
    line-height: 1.2;
}

.lastsection_march {
    display: flex;
    border: 1px solid #80807e4f;
    margin-top: 20px;
    width: 100%;
}

.lastsection_march_1stCard {
    background: #80807e1a;
    padding: 20px;
    width: 33.33%;
}

.lastsection_march_2ndCard {
    padding: 20px;
    width: 33.33%;
}

.lastsection_march_3rdCard {
    background: #80807e1a;
    padding: 20px;
    width: 33.33%;
}

.lastsection_march_h4 {
    color: #871fb5;
    font-family: 'Graphik-Bold';

}