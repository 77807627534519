.weekly_bits_container{
    width: 90%;
    margin:  0% auto;
    margin-top: 30px;
}
.weekly_bits_container h2{
    font-family: 'Graphik-Bold';
    margin-bottom: 20px;
    font-size: 22px;
}
.weekly_bits_container p{
    font-size: 14px;
}
.calendar-container{
    margin-top: 20px;
}
.month-picker{
    border: 2px solid grey;
}
.edition-container{
    margin-top: 30px;
}
.edition-cards-wrapper{
    display: flex;
    justify-content: space-between;
    
}
.edition-cards-box{
    width: 25%;
    /* border: 1px solid grey; */
    border-radius: 0px 0px 5px 5px;
    box-shadow: 1px 1px 3px grey;
    margin-right: 30px;
}
.edition-content{
    padding: 20px 15px 50px 15px;
}
.edition-images1{
    background-image: url("../images/Editions/edition_one.png");
    background-size: cover;
    min-height: 120px;
    width: 100%;
    border-radius: 5px 5px 0px 0px;
    position: relative;
}
.edition-images2{
    background-image: url("../images/Editions/edition_two.png");
    background-size: cover;
    min-height: 120px;
    width: 100%;
    border-radius: 5px 5px 0px 0px;
    position: relative;
}
.edition-images3{
    background-image: url("../images/Editions/edition_three.png");
    background-size: cover;
    min-height: 120px;
    width: 100%;
    border-radius: 5px 5px 0px 0px;
    position: relative;
}
.edition-images4{
    background-image: url("../images/Editions/edition_four.png");
    background-size: cover;
    min-height: 120px;
    width: 100%;
    border-radius: 5px 5px 0px 0px;
    position: relative;
}
.date-tile-img{
    width: 74%;
    height: 30px;
    margin-top: -10px;
    position: absolute;
}
.edition-title{
    position: absolute;
    margin-top: -5px;
    margin-left: 5px;
    color: #fff;
    font-weight: 600;
    font-size: 13px !important;
}
.view-more{
    margin-top: 10px;
    color: blueviolet;
    float: right;
    text-decoration: underline;
    cursor: pointer;
    font-size: 13px;
}
::-webkit-datetime-edit { padding: 10px; }
/* ::-webkit-datetime-edit-fields-wrapper { background: silver; } */
/* ::-webkit-datetime-edit-text { color: red; padding: 0 0.3em; } */
::-webkit-datetime-edit-month-field { color: purple; }
/* ::-webkit-datetime-edit-day-field { color: green; } */
::-webkit-datetime-edit-year-field { color: purple; }
::-webkit-inner-spin-button { display: none; }
::-webkit-calendar-picker-indicator { margin-right: 5px;content: "\032C"; background-size: cover; cursor: pointer;}

@media screen and (min-width:1240px) and (max-width:1544px){
   .weekly_bits_container{
        width: 85%;
    }
}
@media screen and (min-width:1545px){
   .weekly_bits_container{
        width: 82%;
    }
}

