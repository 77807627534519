.main-container {
    font-family: 'Graphik-Regular';
    word-spacing: 2px;
    line-height: 20px;
    margin-bottom: 60px;
}

.home-banner {
    width: 100%;
}

.aboutus-container {
    width: 88%;
    margin: 0% auto;
    margin-top: 30px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}

.aboutus-content h2 {
    /* font-family: 'Graphik-Bold'; */
    font-size: 24px;
    /* font-family: 'Graphik Medium'; */
}

.aboutus-content p {
    margin-top: 15px;
    font-size: 14px;
}

.aboutus-content {
    margin: 5% 0%;
}

.aboutus-img-wrapper,
.aboutus-content {
    width: 50%;
}

.aboutus-img {
    width: 550px;
    margin-top: -20px;
}

.leader-wrapper {
    /* background-image: url("../images/home/leader-bg.png"); */
    background-size: cover;
    width: 100%;
    min-height: 230px;

}

.leader-wrapper h2 {
    padding-top: 35px;
    /* width: 10%; */
    margin: 0% auto;
    margin-bottom: 1%;
    text-align: center;
    font-family: 'Graphik-Bold';
    font-size: 24px;
    /* font-family: 'Graphik Medium'; */
    font-weight: 500;
    text-align: left;
    /* border-right: 4px solid #6c00ba;
    border-left: 4px solid #6c00ba; */
    padding-top: 0px;
    margin-top: 1%;
    text-align: center;
}
.leader-wrapper h2 span{
    position: relative;
}
.leader-wrapper h2 span:before{
    position: absolute;
    width: 4px;
    height: 28px;
    content: " ";
    background: #6c00ba;
    top: 5px;
    left: -15px;
}
.leader-wrapper h2 span:after{
    position: absolute;
    width: 4px;
    height: 28px;
    content: " ";
    background: #6c00ba;
    top: 5px;
    right: -15px;
}
.leader-designation {
    /* height: 65px; */
    width: 70%;
}

.leader-content-row {
    width: 100%;
    margin: 0% auto;
    display: flex;
    justify-content: center;
    padding-top: 1%;
}

.leader-content-box,
.leader-content-box1 {
    min-height: 150px;
    padding: 20px;
    width: 40%;
    margin: 10px 45px 40px 0px;
    background-color: #fff;
    border-radius: 8px;
    /* margin-left: 20px; */
    /* margin-right: 20px; */
    border: 1px solid #6c00ba;
    z-index: 10;
}

.leader-details {
    display: flex;
    align-items: center;
    margin-top: 6px;
    margin-left: 15px;
    /* justify-content: center; */
}

.leader-img {
    margin-right: 20px;
    width: 100px;
}

.leader-designation p {
    color: black;
    font-size: 13px;
    margin-top: 5px;
}

.leader-message {
    font-size: 14px;
}

.right-quote {
    float: right;
}

@media screen and (min-width:1240px) and (max-width:1544px) {

    .leader-content-row,
    .aboutus-container,
    .leader-wrapper h2 {
        /* width: 85%; */
    }
}

@media(min-width:1200px) and (max-width: 1299px) {
    .leader-content-row {
        width: 98%;
    }
}

@media screen and (min-width:1545px) {

    .leader-content-row,
    .aboutus-container,
    .leader-wrapper h2 {
        width: 85%;
    }
}

@media screen and (max-width:767px) {
    .aboutus-container {
        display: block;
    }

    .aboutus-img-wrapper,
    .aboutus-content {
        width: 100%;
    }

    .aboutus-img {
        width: 100%;
        margin-top: 0px;
    }
}

@media screen and (max-width:439px) {
    .leader-content-row {
        display: block;

    }

    .leader-content-box,
    .leader-content-box1 {
        width: 90%;
        margin: 0px auto 20px;
    }

    .leader-wrapper {
        width: 100%;
        min-height: 1px;
        padding-bottom: 30px;
    }
}

.leader-container {
    position: relative;
    margin-bottom: -2%;
}

.leader-container .bg1 {
    position: absolute;
    top: 23%;
    left: -5%;
    width: 18%;
}

.leader-container .bg2 {
    position: absolute;
    bottom: 26%;
    left: 87%;
    width: 13%;
}

.container_div{
    padding-top: 3%;
}