.analytics-span {
    float: right;
    font-size: 12px;
}

.eye-icon{
    width: 10%;
    vertical-align: middle;
}

@media(min-width:1200px){
    .analytics-span {
        position: absolute;
        right: 20px;
    }
}
@media(min-width:992px) and (max-width:1299px){
    .analytics-span{
        font-size: 10px;
    }
}