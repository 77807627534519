.img-responsive {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.SR-Container {
  background-color: #fff;
  /* box-shadow: inset 0 0 10px #999; */
}

.SR-Wrap {
  width: 85%;
  margin: auto;
  /* padding-top: 35px; */
  padding-bottom: 25px;
}
.SR-Wrap p{
  font-size: 14px;
  margin-bottom: 20px;
}
.SR-Wrap h2 {
  font-family: 'Graphik-Bold';
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
  /* border-right: 4px solid #6c00ba;
  border-left: 4px solid #6c00ba;
  width: 21%; */
  margin: 1% auto 2% auto;
  text-align: center;
}

.SR-Wrap h2 span{
  position: relative;
}
.SR-Wrap h2 span:before{
  position: absolute;
  width: 4px;
  height: 28px;
  content: " ";
  background: #6c00ba;
  top: 5px;
  left: -15px;
}
.SR-Wrap h2 span:after{
  position: absolute;
  width: 4px;
  height: 28px;
  content: " ";
  background: #6c00ba;
  top: 5px;
  right: -15px;
}


/* ------------ Carousel styles ------------ */
.SR-Wrap .react-multi-carousel-list {
  /* position: relative !important; */
}

.SR-card-carousel .react-multi-carousel-track {
  gap: 1% !important;
  padding-bottom: 40px !important;
}

.SR-card-carousel .react-multiple-carousel__arrow {
  /* min-width: 1px !important;
  min-height: 1px !important;
  border-radius: 0 !important; */

}

.SR-card-carousel .react-multi-carousel-dot-list {
  bottom: 22px !important;
  border-color: #a7a7a7;
}

.SR-card-carousel .react-multiple-carousel__arrow--left {
  left: calc(4.5% + 1px);
  top:50%;
  /* bottom: 0; */
}

.SR-card-carousel .react-multiple-carousel__arrow--right {
  right: calc(4.5% + 1px);
  top:50%;
  /* bottom: 0; */
}

.SR-card-carousel .react-multiple-carousel__arrow {
  z-index: unset !important;
}

.SR-card-carousel .react-multiple-carousel__arrow--left::before {
  /* content: "\003c"; */
  /* font-family: 'graphik-bold' !important;
  font-size: 2rem; */
}

.SR-card-carousel .react-multiple-carousel__arrow--right::before {
  /* content: "\003e";
  font-family: 'graphik-bold' !important;
  font-size: 2rem; */
}

.SR-card-carousel .react-multiple-carousel__arrow {
  background: none;
}

.SR-card-carousel .react-multiple-carousel__arrow::before {
  color: black;
}

.SR-card-carousel .react-multi-carousel-list {
  position: unset !important;
}


.SR-card-carousel .react-multi-carousel-dot button {
  width: 10px !important;
  height: 10px !important;
}

.videoImagebox {
  position: relative;
}

.videoImagebox h3 {
  text-transform: uppercase;
  color: #fff;
  font-size: 1.7rem;
  font-family: 'graphik-bold';
  text-align: center;
  line-height: 1;
}

.videoCaption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* @media screen and (min-width:768px) and (max-width:1199px) {
  .SR-card-carousel .react-multiple-carousel__arrow--left {
    left: calc(40% + 1px);
    bottom: 0;
  }

  .SR-card-carousel .react-multiple-carousel__arrow--right {
    right: calc(41% + 1px);
    bottom: 0;
  }
}

@media screen and (max-width:767px) {
  .SR-card-carousel .react-multiple-carousel__arrow--left {
    left: calc(30% + 1px);
    bottom: 0;
  }

  .SR-card-carousel .react-multiple-carousel__arrow--right {
    right: calc(31% + 1px);
    bottom: 0;
  }
} */

.SR-Container {
  position: relative;
}

.SR-Container .bg11 {
  position: absolute;
  top: 58%;
  left: 0%;
  width: 8%;

}

.SR-Container .bg12 {
  position: absolute;
  bottom: 67%;
  left: 91%;
  width: 9%;
}
@media(min-width:992px) and (max-width:1299px){
  .SR-Wrap h2{
    /* width: 25%; */
  }
}