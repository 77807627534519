* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  /* overflow-x: clip; */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* 

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #520079; 
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #520079; 
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #520079;
}

.banner-img{
  width: 100%;
  display: block;
}