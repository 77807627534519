/* .Publications-container{
    background-color: #eee;
    box-shadow: inset 0 0 5px #000000;
} */
.Publications-wrap{
    width: 85%;
    margin: auto;
    /* padding-top: 35px; */
    padding-bottom: 45px;
}
.Publications-wrap h2{
    font-size: 24px;
    font-family: 'graphik-bold';
    margin-bottom: 20px;
    font-weight: bold;
    /* border-right: 4px solid #6c00ba;
    border-left: 4px solid #6c00ba;
    width: 23%; */
    margin: 1% auto 2% auto;
    text-align: center;
}

.Publications-wrap h2 span{
    position: relative;
}
.Publications-wrap h2 span:before{
    position: absolute;
    width: 4px;
    height: 28px;
    content: " ";
    background: #6c00ba;
    top: 5px;
    left: -15px;
}
.Publications-wrap h2 span:after{
    position: absolute;
    width: 4px;
    height: 28px;
    content: " ";
    background: #6c00ba;
    top: 5px;
    right: -15px;
}

.Publications-wrap p{
    font-size: 14px;
    margin-bottom: 20px;
}
.Publications-wrap p a{
    color: #a100ff;
}
.Public-wrap .react-multi-carousel-track{
    gap: 1% !important;
    width: 99.8%;
}
.Public-wrap .react-multiple-carousel__arrow {
    background: none;
}
.Public-wrap .react-multiple-carousel__arrow::before {
    color: black;
}


/* @media(min-width:992px){
    .Public-row{
        float: left;
        width: 25%;
    }
} */
.Public-wrap::after{
    display: block;
    clear: both;
    content: "";
}
/* .Public-box{
    margin: 10px;
}
.Public-box.box1{
    margin-left: 0px;
}
.Public-box.box2{
    margin-left: 5px;
    margin-right: 5px;
}
.Public-box.box3{
    margin-left: 5px;
    margin-right: 5px;
}
.Public-box.box4{
    margin-right: 0px;
} */
.Public-box .card-imgs{
    width: 100%;
    display: block;
}
.Public-box h3{
    text-align: center;
    background-color: #fff;
    padding: 10px;
    color: #a100ff;
    font-size: 14px;
    /* border: solid 1px #BBBBBB; */
    /* font-family: 'Graphik-bold'; */
    /* color: #7500c0; */
    font-size: 16px;
    /* border-top: solid 1px #BBBBBB; */
    /* font-family: 'Graphik-bold'; */
}
@media(max-width:1300px){
    .Public-box h3{
        font-size: 14px;
    }
}


/* flip-card-css */
.Publications-container .flip-card {
    background-color: transparent;
    width: 100%;
    /* height: 192px; */
    height: 255px;
    perspective: 1000px;
    margin-bottom: 10px;
}
@media(min-width:992px) and (max-width:1300px){
    .Publications-container .flip-card {
        /* height: 175px; */
        height: 225px;
    }
}
.Publications-container .flip-card:hover{
    cursor: pointer;
} 
.Publications-container .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    /* border: 1px solid #BBBBBB; */
} 
/* .Publications-container .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}  */
.Publications-container .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
} 
.Publications-container .flip-card-front {
    /* background-color: #a100ff; */
    /* border-radius: 20px; */
    /*background-color: #7500c0;
     border-radius: 20px; */
    /* color: black; */
    border: solid 1px #BBBBBB;
}
.initiat-icon{
    vertical-align: middle;
    width: 20px;
}
.Publications-container .flip-card-front h3{
    background-color: #fff;
    color: #520079;
    /* padding: 10px; */
    /* position: absolute; */
    width: 100%;
    bottom: 0px;
    text-align: left;
    /* min-height: 81px; */
    /* border-top: solid 1px #BBBBBB; */
}
.Publications-container .flip-card-back {
    background: #520079;
    /* border-radius: 20px; */
    color: white;
    transform: rotateY(180deg);
}

.Publications-container .flip-card-back h4{
    width: 90%;
    margin: auto;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.Publications-container .flip-card-back p{
    width: 90%;
    margin: auto;
    font-size: 10px;
}
.Public-wrap{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1%;
}
@media(min-width:1300px){
    .Publications-container .flip-card-back h4{
        font-size: 14px;
    }
    .Publications-container .flip-card-back p{
        font-size: 12px;
    }
}
.Publications-container .flip-card-back a{
    color: #fff;
}
.Publications-container .is-flipped{
    transform: rotateY(180deg);
}
@media only screen and (min-width:600px) and (max-width:991px){
    /* .Public-row{
        float: left;
        width: 50%;
    } */
    .Public-wrap {
        grid-template-columns: 1fr 1fr;
        gap: 3% 1%;
      }
      .Publications-container .flip-card-back h4{
        margin-top: 10px;
        margin-bottom: 10px;
      }
}
/* @media only screen and (max-width:599px){
    .Public-wrap {
        display: block;
      }
      .Public-row{
        margin-bottom: 20px;
      }
} */

/* previous year edition tab  */
.Publications-box-row {
    margin-bottom: 10px;
    display: flex;
    margin-top: 25px;
}

.Publications-box-row-para.active {
    color: #8a00c0eb;
    /* font-weight: bold; */
    /* padding-right: 1%; */
}

.Publications-box-row-para{
    font-family: "Graphik-Medium";
}
.Publications-box-row-para.inactive {
    color: #c6c6c6;
}

.Publications-box-row .vertical-line {
    border-left: 2px solid #c6c6c6;
    padding-right: 20px;
    margin-left: 20px;
}
.Publications-box-row p{
    font-size: 16px !important;
    margin-bottom: 0px !important;
}
.Publications-box{
    padding-top: 1%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 2%;
    row-gap: 5%;
}
.Publications-container{
    position: relative;
    margin-bottom: -3%;
}
.Publications-container .bg7{
    position: absolute;
    top: 77%;
    left: 0%;
    width: 7%;
    
}
.Publications-container .bg8{
    position: absolute;
    bottom: 77%;
    left: 92%;
    width: 8%;
}
@media(min-width:992px) and (max-width:1299px){
    .Publications-wrap h2{
        /* width: 28%; */
    }
}
