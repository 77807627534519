.monthly-roundup-container {
    /* margin-top: 20px; */
}

.mr-wrapper {
    /* background-image: url("../images//MonthlyRoundup/Monthly-roundup-bg.png"); */
    /* background-size: cover; */
    /* min-height: 480px; */
    width: 100%;
    /* margin-bottom: 45px; */
}

.mr-box {
    width: 85%;
    /* padding-top: 35px; */
    margin: 0% auto;
}

.mr-box h2 {
    font-family: 'graphik-bold';
    font-size: 24px;
    font-weight: bold;
    /* border-right: 4px solid #6c00ba;
    border-left: 4px solid #6c00ba; 
    width: 35%; */
    margin: auto;
    text-align: center;
}
.mr-box h2 span{
    position: relative;
}
.mr-box h2 span:before{
    position: absolute;
    width: 4px;
    height: 28px;
    content: " ";
    background: #6c00ba;
    top: 5px;
    left: -15px;
}
.mr-box h2 span:after{
    position: absolute;
    width: 4px;
    height: 28px;
    content: " ";
    background: #6c00ba;
    top: 5px;
    right: -15px;
}
.mr-box .mr-para {
    margin-top: 25px;
    font-size: 14px;
}

.mr-box-row {
    margin-bottom: 10px;
    display: flex;
    margin-top: 25px;
}

.mr-box-row-para.active {
    color: #8a00c0eb;
    /* font-weight: bold; */
    /* padding-right: 1%; */
}

.mr-box-row-para{
    font-family: "Graphik-Medium";
}
.mr-box-row-para.inactive {
    color: #c6c6c6;
}

.mr-box-row .vertical-line {
    border-left: 2px solid #c6c6c6;
    padding-right: 20px;
    margin-left: 20px;
}


.calender-tile {
    position: relative;
    width: 30%;
    margin-top: 30px;
}

.mr-tile {
    position: absolute;
    width: 236px;
    text-align: center;
}

.mr-tile-header {
    position: absolute;
    color: #fff;
    margin-left: 20px;
    margin-top: 6px;
    font-size: 14px;
}

.mr-calendar-icon {
    width: 20px;
    margin-right: 10px;
    margin-bottom: -4px;
}

.mr-arrow {
    width: 20px;
}

.mr-box-container {
    margin-top: 20px;
    position: relative;
    width: 100%;
    /* display: flex;
    justify-content: space-between; */
}

.mr-box-wrapper1 {
    background-image: url("../images/MonthlyRoundup/mr-card1.jpg");
    background-size: cover;
    width: 100%;
    min-height: 200px;
    position: relative;
    text-align: center;
    cursor: pointer;
}

.mr-box-wrapper {
    border: 1px solid #ddd;
    position: relative;
    box-shadow: 9px 0px 10px -2px #ddd;
}

.mr-card_link {
    text-decoration: none;
    color: black;
}

/* .mr-live-box{
    top: 5px;
    position: absolute;
    background-color: red;
    color: white;
    font-size: 12px;
    right: 13px;
    padding-right: 20px;
    font-weight: bold;
    border-radius: 5px;
    padding-left: 10px;
} */
.mr-live-dot {
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: white;
    border-radius: 50%;
    top: 5px;
    right: 7px;
}

.mr-box-wrapper2 {
    background-image: url("../images/MonthlyRoundup/mr-card2.jpg");
    background-size: cover;
    width: 100%;
    min-height: 200px;
    position: relative;
    text-align: center;
    opacity: 0.3;
}

.mr-box-wrapper3 {
    background-image: url("../images/MonthlyRoundup/mr-card3.jpg");
    background-size: cover;
    width: 100%;
    min-height: 200px;
    position: relative;
    text-align: center;
    opacity: 0.3;
}

.mr-box-wrapper4 {
    background-image: url("../images/MonthlyRoundup/mr-card4.jpg");
    background-size: cover;
    width: 100%;
    min-height: 200px;
    position: relative;
    text-align: center;
    opacity: 0.3;
}

.mr-card {
    margin-right: 20px;
    margin-left: 2px;
}

.mr-card1 {
    margin-right: 20px;
    margin-left: 2px;
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
}

.mr-box-text {
    padding: 10px;

}

.mr-card p {
    font-size: 14px;
    font-weight: bold;
}

.mr-card1 p {
    font-size: 14px;
    font-weight: bold;
}

.mr-box-content {
    /* position: absolute; */
    /* width: 100%; */
    padding-top: 5px;
}

.rating_section {
    padding-right: 10px;
    padding-top: 2px;
    font-size: 14px;
}

.mr-bottom-tile {
    position: absolute;
    width: 100%;
    height: 30px;
    bottom: 0;
    left: 0;
}

.mr-box-text h5 {
    color: #520079;
}

.monthly_carousel .react-multiple-carousel__arrow--left {
    left: calc(-5% + 1px) !important;
}

.monthly_carousel .react-multiple-carousel__arrow::before {
    color: black;
}

.monthly_carousel .react-multiple-carousel__arrow {
    background: none;
}

.monthly_carousel .react-multiple-carousel__arrow--right {
    right: calc(-4% + 1px) !important;
}

.monthly_carousel .react-multiple-carousel__arrow:disabled {
    cursor: default;
    opacity: 0.3;
}

.react-multi-carousel-list {
    position: unset !important;
}

.monthly_carousel .react-multiple-carousel__arrow {
    z-index: unset !important;
  }

.monthly_tile {
    width: 100%;
}

.monthly_carousel .react-multi-carousel-dot-list {
top:110%;
}
/* @media screen and (min-width:1240px) and (max-width:1544px){
    .mr-box{
         width: 85%;
     }
 }
 @media screen and (min-width:1545px){
    .mr-box{
         width: 82%;
     }
 } */


@media screen and (min-width:1025px) and (max-width:1140px) {
    .mr-box-text h5 {
        font-size: 12px;
    }
}

@media(min-width:992px) and (max-width:1299px){
    .leader-wrapper h2{
        /* width: 12%; */
    }
    .mr-box h2{
        /* width: 43%; */
    }
}

.monthly-roundup-container{
    position: relative;
    margin-bottom: -1%;
}
.monthly-roundup-container .bg3{
    position: absolute;
    bottom: 46%;
    left: 92%;
    width: 8%;
}
.monthly-roundup-container .bg4{
    position: absolute;
    top: 84%;
    left: 0%;
    width: 7%;
}