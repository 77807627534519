.afforestation_span{
    font-weight: bold;
    color:#7500c0;
}

.pondman_content_div{
    margin-top: 20px;
}

.meeting_link{
    color:#1711da8c;
}

.pondman_para{
    padding-bottom: 2px !important;
}

.pondman_mid_para{
    margin-top: 15px;
}

.pondman_wrap{
    display: grid;
    grid-template-columns: 30% 70%;
    margin-top: 20px;
}

.pondman-box_header{
    color: #7500c0;
    margin-top: 0px !important;
}

.pondman-row.row2 {
    margin-left: 50px;
}

.talk_li{
    padding-bottom: 8px;
}

.pondman_last_apara{
    margin-top: 10px;
}
/* @media (min-width: 768px) {
.pondman-row.row1 {
        width: 30%;
    }
.pondman-row.row2 {
    width: 70%;
}
.pondman-row {
    float: left;
}
} */

