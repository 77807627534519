.header__middle .nav-links {
  text-decoration: none;
  padding: 6px 25px;
  display: inline-block;
  color: black;
  width: 100%;
  font-size: 13px;
  /* border-bottom: 1px solid #460073; */
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  /* min-height: 53px; */
}

.main-nav {
  /* position: relative; */
}

.header__middle .nav-links .header__middle .nav-links:hover {
  background: #fff;
  color: #000 !important;
  border-left: 1px solid #460073;
  border-right: 1px solid #460073;
  /* padding: 16px !important; */
}

.header__middle .nav-links:active {
  background: #fff;
  color: #000 !important;
  border-left: 1px solid #460073;
  border-right: 1px solid #460073;
  /* padding: 16px !important; */
}

.is-active {
  color: #000 !important;
  background: #fff;
  padding: 16px !important;
  border-left: 1px solid #460073 !important;
  border-right: 1px solid #460073 !important;
}

.main-nav .home-icon {
  width: 80%;
}

ul.main-menu {
  list-style-type: none;
  display: block;
  width: 100%;
  /* position: absolute; */
  z-index: 10000;
  left: 1%;
  top: 12px;
  color: white !important;
}

@media(min-width:768px) {
  ul.main-menu {
    width: 93%;
    margin: auto;
  }
}

.header__middle li {
  list-style-type: none;
  color: #E6DCFF;
}

@media(min-width:992px) {
  .menu-item {
    display: inline-block;
    /* margin-left: 5px;
        margin-right: 5px; */
    /* margin-top: 5px; */
    /* max-width: 15%; */
    /* text-align: center; */
    vertical-align: middle;
    /* min-height: 53px; */
  }

}

.header__middle {
  width: 100%;
  background-color: #f6f6f6;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
}


.menu-item:hover>a {
  /* color: black !important; */
}

@media(max-width:991px) {
  .header__middle__menus {
    width: 20%;
  }

  .main-nav .menubar__button:hover {
    background-color: #7500c0;
  }

  .main-nav .menu-item:hover>a {
    color: #E6DCFF !important
  }

  .main-nav .menubar__button {
    display: block !important;
    background-color: #7500c0;
    /* color: #E6DCFF; */
    padding: 6px 7px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    z-index: 10037;
  }

  .main-nav .menubar__button svg {
    font-size: 27px;
  }

  .main-nav ul.menuq2 {
    display: block !important;
    /* position: absolute; */
    /* left: 0; */
    /* z-index: 10007; */
    background-color: #460073;
    /* right: 0; */
    padding-bottom: 15px;
  }

  .main-nav .menu-item a {
    text-align: center;
    color: #E6DCFF;
  }

  .main-nav ul.main-menu {
    display: none
  }
}

.main-menu::after {
  display: block;
  content: "";
  clear: both;
}