.scroll-to-top {
  position: fixed;
  bottom: 2.5rem;
  right: 0.7rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.up-arrow{
  width: 100%;
  height: auto;
}